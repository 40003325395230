import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import { Typography } from "@material-ui/core";
import { loadData } from "./tools";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import QWRConfig from "./qwranks-webapp-config.json";

enum QWRStatEnum {
  Top10Lg = "top10_lg",
  Top10Rl = "top10_rl",
  Top10Efficiency = "top10_efficiency",
  Top10ItemControl = "top10_item_control",
  Top10Damage = "top10_damage",
  Top10Frags = "top10_frags",
}

interface QWRStat {
  name: string;
  [value: string]: string;
}

interface QWRStats {
  top10_lg: Array<QWRStat>;
  top10_rl: Array<QWRStat>;
  top10_efficiency: Array<QWRStat>;
  top10_item_control: Array<QWRStat>;
  top10_damage: Array<QWRStat>;
  top10_frags: Array<QWRStat>;
}

function Stats() {
  const [stats, setStats] = useState<QWRStats>();

  const goPlayerDetails = (name: string) => {
    window.location.href = `${QWRConfig.host}/player/${name}`;
  };

  const loadStats = async () => {
    const _stats: QWRStats = await loadData<QWRStats>(
      `${QWRConfig.backend}/stats`
    );
    setStats(_stats);
  };

  useEffect(() => {
    loadStats();
  }, []);

  const generateStatTable = (stat: QWRStatEnum) => {
    if (!stats) return;
    const rows = [];
    for (let i = 0; i < stats[stat].length; i++) {
      let key: string = "";
      let percentageSymbol: boolean = false;
      if (stat === QWRStatEnum.Top10Lg) {
        key = "lg_accuracy";
        percentageSymbol = true;
      }
      if (stat === QWRStatEnum.Top10Rl) {
        key = "rl";
        percentageSymbol = true;
      }
      if (stat === QWRStatEnum.Top10Efficiency) {
        key = "efficiency";
        percentageSymbol = true;
      }
      if (stat === QWRStatEnum.Top10Frags) key = "frags";
      if (stat === QWRStatEnum.Top10Damage) key = "damage_given";
      if (stat === QWRStatEnum.Top10ItemControl) {
        key = "score";
        percentageSymbol = true;
      }

      rows.push(
        <TableRow key={key}>
          <TableCell>{i + 1}</TableCell>
          <TableCell>
            <a onClick={() => goPlayerDetails(stats[stat][i].name)}>
              {stats[stat][i].name}
            </a>
          </TableCell>
          <TableCell>
            {stats[stat][i][key]}
            {percentageSymbol ? "%" : ""}
          </TableCell>
        </TableRow>
      );
    }

    let label = "";
    if (stat === QWRStatEnum.Top10Lg) label = "LG Acc";
    if (stat === QWRStatEnum.Top10Rl) label = "RL Acc";
    if (stat === QWRStatEnum.Top10Efficiency) label = "Efficiency";
    if (stat === QWRStatEnum.Top10Frags) label = "Frags";
    if (stat === QWRStatEnum.Top10Damage) label = "Damage";
    if (stat === QWRStatEnum.Top10ItemControl) label = "Control";

    const table = (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>{label}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    );

    return table;
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h5">Stats</Typography>
      {stats && (
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <Typography variant="h6">Top 10 LG</Typography>
            {generateStatTable(QWRStatEnum.Top10Lg)}
          </Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <Typography variant="h6">Top 10 RL</Typography>
            {generateStatTable(QWRStatEnum.Top10Rl)}
          </Grid>
          <Grid item xs={12} md={12}></Grid>
          {/* ----------------------------------------------------- */}
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <Typography variant="h6">Top 10 Item Control</Typography>
            {generateStatTable(QWRStatEnum.Top10ItemControl)}
          </Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <Typography variant="h6">Top 10 Damage</Typography>
            {generateStatTable(QWRStatEnum.Top10Damage)}
          </Grid>
          {/* ----------------------------------------------------- */}
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <Typography variant="h6">Top 10 Frags</Typography>
            {generateStatTable(QWRStatEnum.Top10Frags)}
          </Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <Typography variant="h6">Top 10 Efficiency</Typography>
            {generateStatTable(QWRStatEnum.Top10Efficiency)}
          </Grid>
          {/* ----------------------------------------------------- */}
        </Grid>
      )}
    </div>
  );
}

export default Stats;

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ResponsiveContainer, PieChart, Pie } from "recharts";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { loadData, translateEloToDiv } from "./tools";
import { useParams } from "react-router-dom";
import MatchList from "./MatchList";
import { QWRRequestResult, QWRPlayerPerformance } from "./types";
import QWRConfig from "./qwranks-webapp-config.json";

interface QWRMapDistribution {
  map: string;
  played: number;
  name: string;
}

function PlayerDetails() {
  const [mapDistribution, setMapDistribution] = useState<
    Array<QWRMapDistribution>
  >([]);
  const [playerPerformance, setPlayerPerformance] =
    useState<QWRPlayerPerformance | null>(null);
  const [notEnoughMatches, setNotEnoughMatches] = useState<boolean>(false);
  const params = useParams();
  const { name } = params;

  const loadMapDistribution = async () => {
    const _mapDistribution = await loadData<Array<QWRMapDistribution>>(
      `${QWRConfig.backend}/playermapdistribution/${name}`
    );
    setMapDistribution(_mapDistribution);
  };

  const loadPlayerPerformance = async () => {
    const requestResult = await loadData<
      QWRRequestResult<QWRPlayerPerformance>
    >(`${QWRConfig.backend}/playerperformance/${name}`);

    if (requestResult.errorId === 0) {
      setPlayerPerformance(requestResult.data);
    } else {
      setNotEnoughMatches(true);
    }
  };

  useEffect(() => {
    loadMapDistribution();
    loadPlayerPerformance();
  }, []);

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Box>
        <Typography variant="h6">Player: {name}</Typography>
        <Typography variant="body2">
          ELO:{" "}
          {playerPerformance &&
            `${playerPerformance.elo} (${translateEloToDiv(
              playerPerformance.elo
            )})`}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9} style={{ height: 600 }}>
          {mapDistribution.length > 0 && (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  key={Math.random()}
                  isAnimationActive={true}
                  data={mapDistribution}
                  dataKey="played"
                  nameKey="map"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="rgb(140, 140, 140)"
                  label={(entry) => entry.map + ": " + entry.played}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="Player average performance"
              style={{ minHeight: 590 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Performance per Game</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              {!notEnoughMatches && playerPerformance && (
                <TableBody>
                  <TableRow key="frags">
                    <TableCell component="th" scope="row">
                      FRAGS
                    </TableCell>
                    <TableCell align="right">
                      {playerPerformance.frags}
                    </TableCell>
                  </TableRow>
                  <TableRow key="frags">
                    <TableCell component="th" scope="row">
                      DAMAGE
                    </TableCell>
                    <TableCell align="right">{playerPerformance.gvn}</TableCell>
                  </TableRow>
                  <TableRow key="efficiency">
                    <TableCell component="th" scope="row">
                      EFFICIENCY
                    </TableCell>
                    <TableCell align="right">
                      {playerPerformance.efficiency}%
                    </TableCell>
                  </TableRow>
                  <TableRow key="lgacc">
                    <TableCell component="th" scope="row">
                      LG ACCURACY
                    </TableCell>
                    <TableCell align="right">
                      {playerPerformance.lgacc}%
                    </TableCell>
                  </TableRow>
                  <TableRow key="rl">
                    <TableCell component="th" scope="row">
                      RL ACCURACY
                    </TableCell>
                    <TableCell align="right">{playerPerformance.rl}%</TableCell>
                  </TableRow>
                  <TableRow key="dh">
                    <TableCell component="th" scope="row">
                      RL DIRECT HITS
                    </TableCell>
                    <TableCell align="right">{playerPerformance.dh}</TableCell>
                  </TableRow>
                  <TableRow key="itemcontrol">
                    <TableCell component="th" scope="row">
                      ITEM CONTROL
                    </TableCell>
                    <TableCell align="right">
                      {playerPerformance.itemcontrol}%
                    </TableCell>
                  </TableRow>
                  <TableRow key="self">
                    <TableCell component="th" scope="row">
                      SELF DAMAGE
                    </TableCell>
                    <TableCell align="right">
                      {playerPerformance.self}
                    </TableCell>
                  </TableRow>
                  <TableRow key="sg">
                    <TableCell component="th" scope="row">
                      SG ACCURACY
                    </TableCell>
                    <TableCell align="right">{playerPerformance.sg}%</TableCell>
                  </TableRow>
                </TableBody>
              )}
              {notEnoughMatches && (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      A player needs to play at least 15 matches to appear in
                      the statistics.
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <MatchList name={name} />
    </div>
  );
}

export default PlayerDetails;

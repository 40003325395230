import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";
import SearchBox from "./SearchBox";
import QWRConfig from "./qwranks-webapp-config.json";

const navigationLinks = [
  { name: "Latest Matches", href: "/" },
  { name: "Ranking", href: "/ranking" },
  { name: "Stats", href: "/stats" },
  { name: "Rules", href: "/rules" },
];

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: 20,
  },
  avatar: {
    marginRight: "auto",
    color: "white",
    backgroundColor: "black",
    borderRadious: 0,
    height: 30,
    width: 200,
    border: "2px solid gray",
    borderLeft: "12px solid transparent",
    borderRight: "12px solid transparent",
  },
}));

function Header() {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <AppBar position="sticky" color="default">
      <Container>
        <Toolbar disableGutters style={{ justifyContent: "space-between" }}>
          <div>
            <Typography variant="h5">
              <a href={QWRConfig.host}>QuakeWorld Duel Ranks</a>
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchBox />
            <IconButton>
              <MenuIcon onClick={() => setOpen(true)} />
            </IconButton>
          </div>
        </Toolbar>
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <div>
          <IconButton>
            <ChevronRightIcon onClick={() => setOpen(false)} />
          </IconButton>
        </div>
        <Divider />
        <List>
          {navigationLinks.map((item, index) => (
            <ListItem key={index}>
              <NavLink to={item.href} onClick={() => setOpen(false)}>
                <Link
                  className={styles.link}
                  color="textPrimary"
                  variant="button"
                  underline="none"
                >
                  {item.name}
                </Link>
              </NavLink>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </AppBar>
  );
}

export default Header;

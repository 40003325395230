import React, { useState, useEffect, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Theme, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { loadData, getDate } from "./tools";
import { QWRDate, QWRMatch } from "./types";
import QWRConfig from "./qwranks-webapp-config.json";
import { CircularProgress } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface MatchListProps {
  name?: string;
}

const MatchList: React.FC<MatchListProps> = ({ name }) => {
  const [matches, setMatches] = useState<Array<QWRMatch>>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [noMoreMatches, setNoMoreMatches] = useState<boolean>(false);
  const largeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const loadMatches = async (pageIndex: number) => {
    const url = name
      ? `${QWRConfig.backend}/matchesbyplayer/${name}/${pageIndex}`
      : `${QWRConfig.backend}/matches/${pageIndex}`;
    const _matches: Array<QWRMatch> = await loadData<Array<QWRMatch>>(url);
    if (_matches.length > 0) {
      setMatches([...matches, ..._matches]);
    } else {
      setNoMoreMatches(true);
    }
  };

  const downloadDemo = (matchId: number) => {
    window.location.href = `${QWRConfig.backend}/download/${matchId}`;
  };

  const goMatchDetails = (matchId: number) => {
    window.location.href = `${QWRConfig.host}/matchdetails/${matchId}`;
  };

  const goPlayerDetails = (name: string) => {
    window.location.href = `${QWRConfig.host}/player/${name}`;
  };

  const handleScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      loadMatches(pageIndex + 1);
      setPageIndex(pageIndex + 1);
    }
  }, [matches]);

  const renderLoadingSpinner = () => {
    return (
      <Paper style={{ marginTop: 20 }} elevation={1}>
        <Grid item xs={12} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        </Grid>
      </Paper>
    );
  };

  function greenDot() {
    return <span style={{ color: "green" }}>•&nbsp;</span>;
  }

  useEffect(() => {
    window.onscroll = handleScroll;
  }, [matches]);

  useEffect(() => {
    loadMatches(0);
  }, []);

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Typography variant="h6">
        Last tracked Duels {name && ` of ${name}`}
      </Typography>
      {matches &&
        matches.map((match, index) => {
          const matchDate: QWRDate = getDate(match.match_time);
          return (
            <>
              <Paper
                style={{ marginTop: 20 }}
                elevation={1}
                key={match.match_id}
              >
                <Grid container spacing={1}>
                  <Grid container item xs={4} md={1}>
                    <img
                      src={QWRConfig.host + "/" + match.map + ".jpg"}
                      style={{
                        width: "100%",
                        marginLeft: 6,
                        marginTop: 2,
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = QWRConfig.host + "/dm2.jpg";
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    md={8}
                    alignItems="center"
                    justifyContent="center"
                    direction={"row"}
                  >
                    <Grid item xs={12} md={4}>
                      <div
                        style={{
                          position: largeScreen ? "relative" : "static",
                          top: 8,
                          display: "flex",
                          flexDirection: largeScreen
                            ? "column"
                            : "column-reverse",
                        }}
                      >
                        <div>
                          {" "}
                          <Typography
                            variant={largeScreen ? "h5" : "h6"}
                            align={largeScreen ? "right" : "center"}
                          >
                            {match.player1_frags > match.player2_frags
                              ? greenDot()
                              : null}
                            <a
                              onClick={() =>
                                goPlayerDetails(match.player1_name)
                              }
                            >
                              {match.player1_name}
                            </a>
                          </Typography>
                        </div>
                        <div>
                          {" "}
                          <Typography
                            variant="body2"
                            align={largeScreen ? "right" : "center"}
                          >
                            <a>({match.player1_elo})</a>
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: largeScreen ? "relative" : "static",
                          top: 8,
                        }}
                      >
                        <table>
                          <tr>
                            <td style={{ width: 32 }}>
                              <Typography
                                variant={largeScreen ? "h5" : "h6"}
                                align="center"
                              >
                                {match.player1_frags}
                              </Typography>
                            </td>
                            <td>:</td>
                            <td style={{ width: 32 }}>
                              <Typography
                                variant={largeScreen ? "h5" : "h6"}
                                align="center"
                              >
                                {match.player2_frags}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: 32 }}>
                              <Typography variant="body2" align="center">
                                (
                                {match.player1_elochange > 0
                                  ? `+${match.player1_elochange}`
                                  : match.player1_elochange}
                              </Typography>
                            </td>
                            <td>:</td>
                            <td style={{ width: 32 }}>
                              <Typography variant="body2" align="center">
                                {match.player2_elochange > 0
                                  ? `+${match.player2_elochange}`
                                  : match.player2_elochange}
                                )
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div
                        style={{
                          position: largeScreen ? "relative" : "static",
                          top: 8,
                        }}
                      >
                        <div>
                          <Typography
                            variant={largeScreen ? "h5" : "h6"}
                            align={largeScreen ? "left" : "center"}
                          >
                            <a
                              onClick={() =>
                                goPlayerDetails(match.player2_name)
                              }
                            >
                              {match.player2_name}
                            </a>
                            &nbsp;
                            {match.player2_frags > match.player1_frags
                              ? greenDot()
                              : null}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            align={largeScreen ? "left" : "center"}
                          >
                            <a>({match.player2_elo})</a>
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={1}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        padding: 12,
                      }}
                    >
                      <Typography
                        variant="button"
                        align="center"
                        style={{
                          marginLeft: "-100%",
                          marginRight: "-100%",
                          textAlign: "center",
                        }}
                        color={match.ranked ? "secondary" : "inherit"}
                      >
                        {match.ranked ? "RANKED" : "UNRANKED"}
                      </Typography>
                      <Typography variant="body1" align="center">
                        {match.map}
                      </Typography>
                      <Typography variant="body1" align="center">
                        {`${matchDate.day}.${matchDate.month}.${matchDate.year}`}
                      </Typography>
                      <Typography variant="body1" align="center">
                        {`${matchDate.hour}:${matchDate.minute}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={2}
                    style={{
                      padding: 12,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Button
                        onClick={() => goMatchDetails(match.match_id)}
                        variant="text"
                      >
                        Match Details
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => downloadDemo(match.match_id)}
                        variant="text"
                      >
                        Download
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              {index === matches.length - 1 && !noMoreMatches
                ? renderLoadingSpinner()
                : null}
            </>
          );
        })}
    </div>
  );
};

export default MatchList;

import { QWRDate, QWRMatch } from "./types";

export const getDate = (s: string): QWRDate => {
  const d: Date = new Date(s);

  const day: number = d.getDate();
  const month: number = d.getMonth() + 1;
  const year: number = d.getFullYear() - 2000;
  const hour: number = d.getHours();
  const minute: number = d.getMinutes();

  return {
    day: day.toString().length > 1 ? day.toString() : "0" + day,
    month: month.toString().length > 1 ? month.toString() : "0" + month,
    year: year.toString(),
    hour: hour.toString().length > 1 ? hour.toString() : "0" + hour,
    minute: minute.toString().length > 1 ? minute.toString() : "0" + minute,
  };
};

export const loadData = <T>(url: string): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    try {
      const rawData = await fetch(url);
      const data = await rawData.json();
      return resolve(data);
    } catch (e) {
      return reject();
    }
  });
};

export const translateEloToDiv = (elo: number) => {
  if (elo >= 2700) {
    return "div0";
  }
  if (elo >= 2600) {
    return "high div1";
  }
  if (elo >= 2400) {
    return "med div1";
  }
  if (elo >= 2200) {
    return "low div1";
  }
  if (elo >= 2000) {
    return "high div2";
  }
  if (elo >= 1800) {
    return "med div2";
  }
  if (elo >= 1600) {
    return "low div2";
  }
  if (elo >= 1400) {
    return "high div3";
  }
  if (elo >= 1200) {
    return "med div3";
  }
  if (elo >= 1000) {
    return "low div3";
  }
  return "div4";
};

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { loadData } from "./tools";
import { useParams } from "react-router-dom";
import { QWRMatch, QWRPlayerPerformance } from "./types";
import QWRConfig from "./qwranks-webapp-config.json";

interface QWRMatchDetails {
  match: QWRMatch;
  playerPerformances: Array<QWRPlayerPerformance>;
}

const MatchDetails = () => {
  const params = useParams();
  const { matchId } = params;
  const [matchDetails, setMatchDetails] =
    useState<QWRMatchDetails | null>(null);

  const calculateArmorPercentages = () => {
    if (!matchDetails) return;
    const totalReds =
      matchDetails.playerPerformances[0].ra +
      matchDetails.playerPerformances[1].ra;

    matchDetails.playerPerformances[0].raPercentage = (
      (matchDetails.playerPerformances[0].ra / totalReds) *
      100
    ).toFixed(0);
    matchDetails.playerPerformances[1].raPercentage = (
      (matchDetails.playerPerformances[1].ra / totalReds) *
      100
    ).toFixed(0);

    const totalMegas =
      matchDetails.playerPerformances[0].mh +
      matchDetails.playerPerformances[1].mh;

    matchDetails.playerPerformances[0].mhPercentage = (
      (matchDetails.playerPerformances[0].mh / totalMegas) *
      100
    ).toFixed(0);
    matchDetails.playerPerformances[1].mhPercentage = (
      (matchDetails.playerPerformances[1].mh / totalMegas) *
      100
    ).toFixed(0);

    const totalYellows =
      matchDetails.playerPerformances[0].ya +
      matchDetails.playerPerformances[1].ya;

    matchDetails.playerPerformances[0].yaPercentage = (
      (matchDetails.playerPerformances[0].ya / totalYellows) *
      100
    ).toFixed(0);
    matchDetails.playerPerformances[1].yaPercentage = (
      (matchDetails.playerPerformances[1].ya / totalYellows) *
      100
    ).toFixed(0);

    const totalGreens =
      matchDetails.playerPerformances[0].ga +
      matchDetails.playerPerformances[1].ga;

    matchDetails.playerPerformances[0].gaPercentage = (
      (matchDetails.playerPerformances[0].ga / totalGreens) *
      100
    ).toFixed(0);
    matchDetails.playerPerformances[1].gaPercentage = (
      (matchDetails.playerPerformances[1].ga / totalGreens) *
      100
    ).toFixed(0);
  };

  const calculateDetailsRows = () => {
    if (!matchDetails) return;

    const detailsRows = [];

    let key: keyof QWRMatch;
    matchDetails.match.downloadURL = `${QWRConfig.backend}/download/${matchDetails.match.id}`;
    for (key in matchDetails?.match) {
      if (key === "valid" || key === "serverinfo") continue;
      const detailsRow = (
        <TableRow key={key}>
          <TableCell align="center">{key}</TableCell>
          <TableCell align="left">{matchDetails?.match[key]}</TableCell>
        </TableRow>
      );
      detailsRows.push(detailsRow);
    }
    return detailsRows;
  };

  const loadMatchDetails = async () => {
    const _matchDetails = await loadData<QWRMatchDetails>(
      `${QWRConfig.backend}/matchdetails/${matchId}`
    );
    setMatchDetails(_matchDetails);
  };

  const goPlayerDetails = (name: string) => {
    window.location.href = `${QWRConfig.host}/player/${name}`;
  };

  useEffect(() => {
    loadMatchDetails();
  }, []);

  calculateArmorPercentages();

  return (
    <div style={{ marginTop: 20 }}>
      {matchDetails && (
        <React.Fragment>
          <Typography variant="h5">
            Match #{matchId}: {matchDetails.match.map}
          </Typography>
          <Grid container spacing={1} style={{ marginTop: 20 }}>
            <Grid item md={2}></Grid>
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">
                        <Typography variant="h5">
                          <a
                            onClick={() =>
                              goPlayerDetails(
                                matchDetails?.playerPerformances[0].name
                              )
                            }
                          >
                            {matchDetails?.playerPerformances[0].name}
                          </a>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">VS</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="h5">
                          <a
                            onClick={() =>
                              goPlayerDetails(
                                matchDetails?.playerPerformances[1].name
                              )
                            }
                          >
                            {matchDetails?.playerPerformances[1].name}
                          </a>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={"frags"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["frags"]}
                      </TableCell>
                      <TableCell align="center">FRAGS</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["frags"]}
                      </TableCell>
                    </TableRow>
                    <TableRow key={"damage"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["gvn"]}
                      </TableCell>
                      <TableCell align="center">DAMAGE</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["gvn"]}
                      </TableCell>
                    </TableRow>
                    <TableRow key={"efficiency"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["efficiency"]}%
                      </TableCell>
                      <TableCell align="center">EFFICIENCY</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["efficiency"]}%
                      </TableCell>
                    </TableRow>
                    <TableRow key={"lgacc"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["lgacc"]}%
                      </TableCell>
                      <TableCell align="center">LG ACCURACY</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["lgacc"]}%
                      </TableCell>
                    </TableRow>
                    <TableRow key={"rl"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["rl"]}%
                      </TableCell>
                      <TableCell align="center">RL ACCURACY</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["rl"]}%
                      </TableCell>
                    </TableRow>
                    <TableRow key={"dh"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["dh"]}
                      </TableCell>
                      <TableCell align="center">RL DIRECT HITS</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["dh"]}
                      </TableCell>
                    </TableRow>
                    <TableRow key={"itemcontrol"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["itemcontrol"]}%
                      </TableCell>
                      <TableCell align="center">ITEM CONTROL</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["itemcontrol"]}%
                      </TableCell>
                    </TableRow>
                    <TableRow key={"ra"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["ra"]}&nbsp;(
                        {matchDetails?.playerPerformances[0]["raPercentage"]}%)
                      </TableCell>
                      <TableCell align="center">RED ARMOR</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["ra"]}&nbsp;(
                        {matchDetails?.playerPerformances[1]["raPercentage"]}%)
                      </TableCell>
                    </TableRow>
                    <TableRow key={"ra"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["mh"]}&nbsp;(
                        {matchDetails?.playerPerformances[0]["mhPercentage"]}%)
                      </TableCell>
                      <TableCell align="center">MEGA HEALTH</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["mh"]}&nbsp;(
                        {matchDetails?.playerPerformances[1]["mhPercentage"]}%)
                      </TableCell>
                    </TableRow>
                    <TableRow key={"ra"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["ya"]}&nbsp;(
                        {matchDetails?.playerPerformances[0]["yaPercentage"]}%)
                      </TableCell>
                      <TableCell align="center">YELLOW ARMOR</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["ya"]}&nbsp;(
                        {matchDetails?.playerPerformances[1]["yaPercentage"]}%)
                      </TableCell>
                    </TableRow>
                    <TableRow key={"ra"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["ga"]}&nbsp;(
                        {matchDetails?.playerPerformances[0]["gaPercentage"]}%)
                      </TableCell>
                      <TableCell align="center">GREEN ARMOR</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["ga"]}&nbsp;(
                        {matchDetails?.playerPerformances[1]["gaPercentage"]}%)
                      </TableCell>
                    </TableRow>
                    <TableRow key={"self"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["self"]}
                      </TableCell>
                      <TableCell align="center">SELF DAMAGE</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["self"]}
                      </TableCell>
                    </TableRow>
                    <TableRow key={"sg"}>
                      <TableCell align="right">
                        {matchDetails?.playerPerformances[0]["sg"]}%
                      </TableCell>
                      <TableCell align="center">SG ACCURACY</TableCell>
                      <TableCell align="left">
                        {matchDetails?.playerPerformances[1]["sg"]}%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
            <Grid item md={2}></Grid>
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>{calculateDetailsRows()}</TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={1} md={2}></Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};

export default MatchDetails;

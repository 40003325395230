import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import { QWRRequestResult, QWRQTVServer } from "./types";
import { loadData } from "./tools";
import QWRConfig from "./qwranks-webapp-config.json";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Rules() {
  const classes = useStyles();
  const [supportedServers, setSupportedServers] = useState<Array<QWRQTVServer>>(
    []
  );

  const loadSupportedServers = async () => {
    const requestResult = await loadData<QWRRequestResult<Array<QWRQTVServer>>>(
      `${QWRConfig.backend}/qtvserver`
    );

    if (requestResult.errorId === 0) {
      setSupportedServers(requestResult.data);
    }
  };

  useEffect(() => {
    loadSupportedServers();
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Grid container spacing={1}>
        <Grid item md={2}></Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">Rules</Typography>
          <Divider />
          <p>
            <Typography variant="h6">1. Acceptance criteria</Typography>
            <p>
              <Typography variant="body2">
                All duels are tracked, which
                <ul>
                  <li>
                    are played on a supported server (see "3. Supported
                    Servers")
                  </li>
                  <li>
                    were initiated by "1on1" or "1on1 prac", "1on1 kombat",
                    "1on1 hammertime", "1on1 qwduel" or basically any "1on1
                    YOU-NAME-IT"
                  </li>
                  <li>use deathmeatch mode 3 (DMM 3)</li>
                  <li>have timelimit of 10 minutes</li>
                  <li>
                    do not have players named "", "unnamed", "player", "/ bro"
                    or ": Timber"
                  </li>
                </ul>
              </Typography>
            </p>
          </p>
          <p>
            <Typography variant="h6">2. Ranked and Unranked Matches</Typography>
            <p>
              <Typography variant="body2">
                <p>
                  <b>
                    The official ranking started at September 1st 2022 20:00 ECT
                  </b>
                </p>
                All duels that meet the aforementioned criteria will be tracked.
                However, only ranked duels will change a player's Elo score
                which determines the player's position in the ranking. Duels
                that are initiated by "1on1" will be tracked and ranked, duels
                that are initiated by "1on1 prac" will only be tracked and not
                affect the player's ranking.
              </Typography>
            </p>
          </p>
          <p>
            <Typography variant="h6">3. Supported Servers</Typography>
            <p>
              <Typography variant="body2">
                QWRanks tracks duels initiated with "1on1" or "1on1 prac" by the
                console from the following servers:
              </Typography>
              <Typography variant="body2">
                <ul>
                  {supportedServers &&
                    supportedServers.map((supportedServer) => (
                      <li>{supportedServer.description}</li>
                    ))}
                </ul>
              </Typography>
            </p>
          </p>
          <p>
            <Typography variant="h6">4. Map pool</Typography>
            <p>
              <Typography variant="body2">
                Any map can be played. However, the initial seeding is done on
                the most played duel maps. (See "6. Elo Seeding")
              </Typography>
            </p>
          </p>
          <p>
            <Typography variant="h6">5. Elo Rating</Typography>
            <p>
              <Typography variant="body2">
                <p>
                  A player's elo rating will only be changed in ranked game
                  initiated by "1on1", NOT by "1on1 prac".
                </p>
                Originally, the Elo is a rating system which was invented to
                rank chess players. It is used for other sports as well as
                eSports. Elo ranking determine whether a player is likely to win
                vs another player. If player A with Elo 2500 plays vs player B
                with Elo 1200, player A will most likely win. Therefore, player
                A's score will basically not go up (+0) after the win and player
                B's score will not go down (-0). If, however, player B wins vs
                player A, player A will lose the maximum of 20 points (-20) and
                player will get the maximum of 20 points (+20).
              </Typography>
            </p>
          </p>
          <p>
            <Typography variant="h6">6. Elo Seeding</Typography>
            <p>
              <Typography variant="body2">
                Players who are known to the QuakeWorld community get an initial
                elo seeding. Based on their past achievements or activity, a
                group of duel experts votes for their initial elo on the
                following classic duel maps:
                <ul>
                  <li>dm2 (16.6%)</li>
                  <li>dm4 (16.6%)</li>
                  <li>dm6 (16.6%)</li>
                  <li>aerowalk (16.6%)</li>
                  <li>ztndm3 (16.6%)</li>
                  <li>bravado (4.2%)</li>
                  <li>shifter (4.2%)</li>
                  <li>toxicity (4.2%)</li>
                  <li>skull (4.2%)</li>
                </ul>
                <p>
                  If a player is unknown, he/she starts with an Elo of 1200 (Med
                  Div3).
                </p>
              </Typography>
            </p>
          </p>
          <p>
            <Typography variant="h6">7. Stats</Typography>
            <p>
              <Typography variant="body2">
                <p>
                  For all players who played at least 15 duels (ranked or
                  unranked), stats will be calculated. Among the well-know stats
                  LG%, RL%, Damage, Frags and Efficiency, there is also the Item
                  control.
                </p>
                <p>
                  The item control counts the number of armors taken by both
                  players, and calculates each players' weighted fraction to it.
                </p>

                <p>
                  Example 1: If player A takes one red and player B takes one
                  red. Each player has 50% item control.{" "}
                </p>
                <p>
                  Example 2: If player A takes one red and player B takes one
                  green, player A has 75% item control and player B 25%, because
                  a red armor is weighted 3 times more than a green armor.
                </p>
                <p>
                  <ul>
                    <li>Red has weight 3</li>
                    <li>Mega has weight 3</li>
                    <li>Yellow has weight 2</li>
                    <li>Green has weight 1</li>
                  </ul>
                </p>
              </Typography>
            </p>

            <Typography variant="h6">8. Divisions</Typography>
            <p>
              <Typography variant="body2">
                Each Elo ranking represents membership to a skill division:
                <ul>
                  <li>div0 starts at 2700</li>
                  <li>high div1 starts at 2600</li>
                  <li>med div1 starts at 2400</li>
                  <li>low div1 starts at 2200</li>
                  <li>high div2 starts at 2000</li>
                  <li>med div2 starts at 1800</li>
                  <li>low div2 starts at 1600</li>
                  <li>high div3 starts at 1400</li>
                  <li>med div3 starts at 1200</li>
                  <li>low div3 starts at 1000</li>
                  <li>div4 is below 800</li>
                </ul>
              </Typography>
            </p>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

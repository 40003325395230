import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Typography, Theme } from "@material-ui/core";
import { loadData } from "./tools";
import { QWRSearchPlayerResult, QWRRequestResult } from "./types";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import QWRConfig from "./qwranks-webapp-config.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    padding: "2px 4px",
    alignItems: "center",
    height: 35,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchResultPaper: {
    position: "absolute",
    top: 34,
    left: 0,
    paddingBottom: 8,
    paddingLeft: 4,
    maxHeight: 500,
    overflow: "auto",
  },
  searchResultItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
}));

export default function SearchBox() {
  const styles = useStyles();
  const largeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const [searchPlayerResults, setSearchPlayerResults] = useState<
    Array<QWRSearchPlayerResult>
  >([]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const searchPlayer = async (name: string) => {
    const requestResult = await loadData<
      QWRRequestResult<Array<QWRSearchPlayerResult>>
    >(`${QWRConfig.backend}/searchplayer?name=${name}`);

    if (requestResult.errorId === 0) {
      setSearchPlayerResults(requestResult.data);
    }
  };

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const q = e.target.value;
    setSearchQuery(q);

    if (q.length > 0) {
      searchPlayer(q);
    }
  };

  const handleClickAway = () => {
    setSearchQuery("");
  };

  const goPlayerDetails = (name: string) => {
    window.location.href = `${QWRConfig.host}/player/${name}`;
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper
        component="div"
        className={styles.root}
        style={{ width: largeScreen ? 250 : 150 }}
      >
        {searchQuery.length > 0 && (
          <Paper
            className={styles.searchResultPaper}
            style={{ width: largeScreen ? 250 : 150 }}
          >
            {searchPlayerResults.map((searchResult) => (
              <Typography
                variant="body2"
                style={{ marginLeft: 8 }}
                className={styles.searchResultItem}
                onClick={() => goPlayerDetails(searchResult.name)}
              >
                {searchResult.name}
              </Typography>
            ))}
          </Paper>
        )}
        <InputBase
          onChange={onChangeHandler}
          className={styles.input}
          placeholder="Player ..."
          value={searchQuery}
          inputProps={{ "aria-label": "search google maps" }}
        />
        <IconButton className={styles.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    </ClickAwayListener>
  );
}

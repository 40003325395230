import React, { useState, useEffect, useCallback } from "react";
import MatchList from "./MatchList";

function LastMatches() {
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <MatchList />
    </div>
  );
}

export default LastMatches;

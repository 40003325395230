import React from "react";
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.scss";

import LastMatches from "./LastMatches";
import PlayerDetails from "./PlayerDetails";
import MatchDetails from "./MatchDetails";
import Stats from "./Stats";
import Rules from "./Rules";
import Sandbox from "./Sandbox";
import Ranking from "./Ranking";
import Container from "@material-ui/core/Container";
import Header from "./Header";
import ReactGA from "react-ga";

const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
});

function App() {
  ReactGA.initialize("UA-237485906-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Header />
      <Container>
        <Routes>
          <Route path="/" element={<LastMatches />} />
          <Route path="/player/:name" element={<PlayerDetails />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/matchdetails/:matchId" element={<MatchDetails />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/sandbox" element={<Sandbox />} />
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

export default App;

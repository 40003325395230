import React, { useState, useEffect, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress } from "@material-ui/core";

import { loadData } from "./tools";
import { QWRRequestResult, QWREloRanking } from "./types";
import QWRConfig from "./qwranks-webapp-config.json";

export default function Ranking() {
  const [eloRankings, setEloRankings] = useState<Array<QWREloRanking>>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [noMoreRankings, setNoMoreRankings] = useState<boolean>(false);

  const loadEloRating = async (pageIndex: number) => {
    const requestResult = await loadData<
      QWRRequestResult<Array<QWREloRanking>>
    >(`${QWRConfig.backend}/eloranking/${pageIndex}`);

    if (requestResult.errorId === 0) {
      const _eloRankings = requestResult.data;
      if (_eloRankings.length > 0) {
        setEloRankings([...eloRankings, ..._eloRankings]);
      } else {
        setNoMoreRankings(true);
      }
    }
  };

  useEffect(() => {
    window.onscroll = handleScroll;
  }, [eloRankings]);

  useEffect(() => {
    loadEloRating(0);
  }, []);

  const goPlayerDetails = (name: string) => {
    window.location.href = `${QWRConfig.host}/player/${name}`;
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      eloRankings.length > 0
    ) {
      loadEloRating(pageIndex + 1);
      setPageIndex(pageIndex + 1);
    }
  }, [eloRankings]);

  const renderLoadingSpinner = () => {
    return (
      <TableRow key="frags">
        <TableCell scope="row"></TableCell>
        <TableCell>
          <CircularProgress color="inherit" />
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  };

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Typography variant="h6">Duel Ranking</Typography>
      <Grid container spacing={1} style={{ marginTop: 20 }}>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="Player average performance"
              style={{ minHeight: 590 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Elo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eloRankings.map((eloRanking, index) => (
                  <>
                    <TableRow key="frags">
                      <TableCell scope="row">{eloRanking.rank}</TableCell>
                      <TableCell>
                        <a onClick={() => goPlayerDetails(eloRanking.name)}>
                          {eloRanking.name}
                        </a>
                      </TableCell>
                      <TableCell>{eloRanking.elo}</TableCell>
                    </TableRow>
                    {index === eloRankings.length - 1 &&
                      !noMoreRankings &&
                      renderLoadingSpinner()}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
    </div>
  );
}
